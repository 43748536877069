.alert-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  // .alert-exit {
  //   opacity: 1;
  // }
  // .alert-exit-active {
  //   opacity: 0;
  //   transform: scale(0.9);
  //   transition: opacity 300ms, transform 300ms;
    
  // }
  
  // div {
  //   width: 100px;
  //   height: 100px;
  //   background-color: red;
  //   position: relative;
  //   animation-name: example;
  //   animation-duration: 4s;
  // }
  
  // @keyframes example {
  //   0%   {background-color:red; left:0px; top:0px;}
  //   25%  {background-color:yellow; left:200px; top:0px;}
  //   50%  {background-color:blue; left:200px; top:200px;}
  //   75%  {background-color:green; left:0px; top:200px;}
  //   100% {background-color:red; left:0px; top:0px;}
  // }