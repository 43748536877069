.table thead th {
  vertical-align: bottom;
  background-color: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  border-bottom-width: 1px;
}
.table-bordered {
  border: 1px solid #f0f0f0;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #f0f0f0;
}
.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: rgba(0, 0, 0, 0.03);
}
.ant-table {
  font-size: 0.85em !important;
}
