
.modal-header {
    height: 65px;
}

.modal-90w { max-width: 90% !important; }
.modal-80w { max-width: 80% !important; }
.modal-70w { max-width: 70% !important; }
.modal-60w { max-width: 60% !important; }
.modal-50w { max-width: 50% !important; }
.modal-40w { max-width: 40% !important; }