.icon-search {
    margin: auto;
    font-size: 1.5em;
    position: absolute;
    line-height: 1;
    top: 0;
    right: 0;
    width: 45px;
    opacity: 0.5;
    transition: opacity 0.3s ease;
  }
  .input-search {
    border-radius: 8px;
  }
  
  .search-results {
    visibility: hidden;
    opacity: 0;
    height:0px;
    top: 100%;
    left: 0;
    background: #fff;
    padding: 8px 16px;
    margin-top: 0.5em;
    margin-bottom: 1em;
    width: 65%;
    border-radius: 0.28571429rem;
    -webkit-box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
    border: 1px solid #d4d4d5;
    z-index: 998;
    transition: visibility 0.5s, opacity 0.2s linear;
  }
  

