.card-process {
  margin: 0;
  border-radius: 8px;
  color: #fff;
  margin-right: 12px;
  max-height: 45px;
}
.card-process .card-none {
  background-color: red !important;
}
.card-process .col {
  min-width: 120px;
  text-align: center;
  padding-left: 12px;
}
.card-process .card-body {
  padding: 8px;
  border-radius: 8px;
  max-height: 45px;
}
.card-process {
  min-width: 120px;
  text-align: center;
  margin: 12px;
}
.fa fa-times-circle fa-close-o {
  font-size: 26px !important;
}

.btn-process-state {
  height: 35px;
  min-width: 35px;

  // box-shadow: 0px 0px 3px 0.2px #888888;
}
.card-process-product {
  min-width: 120px;
  width: 100%;
  border-radius: 8px;
  background-color: #e7ece60a;
  color: #fff;
  border: 0;
}

.p-after {
  font-weight: 600;
  color: #fff;
  background-color: #fbb888bd !important;
  border-radius: 8px;
}
.p-before {
  font-weight: 600;
  color: #fff;
  background-color: #6d91cb !important;
  border-radius: 8px;
}
.p-binding {
  font-weight: 600;
  color: #fff;
  background-color: #a59679 !important;
  border-radius: 8px;
}
.p-outsource {
  font-weight: 600;
  color: #fff;
  background-color: #cab7a6 !important;
  border-radius: 8px;
}
.p-packing {
  font-weight: 600;
  color: #fff;
  background-color: #0011ff !important;
  border-radius: 8px;
}
.p-printing {
  font-weight: 600;
  color: #fff;
  background-color: #90cc86 !important;
  border-radius: 8px;
}
.p-compile {
  font-weight: 600;
  color: #fff;
  background-color: #af9fcc !important;
  border-radius: 8px;
}
.p-add {
  font-weight: 600;
  color: #fff;
  background-color: #3fff00 !important;
  border-radius: 8px;
  min-width: 35px !important;
}
.delete-process {
  float: right;
  color: red;
  background: transparent;
  border: transparent;
  padding: 0px;
  padding-top: 8px;
  font-size: 20px;
}

.thead-tr {
  color: #fff;
  font-weight: 600;
}
