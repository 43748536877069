@import url('https://fonts.googleapis.com/css?family=Kanit:400,300&subset=thai,latin');
.container-notification-list {
    top: 25px !important;
    left: -300px !important;
    transform: inherit !important;
    position: absolute;
    bottom: 0px;
    right: 0px;
    font-size: 1.5em;
    width: 350px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 3px 8px;
    opacity: 0;
    pointer-events: none;
    //transform: translateY(calc(100% + 24px)) translateX(10%);
    z-index: 1;
    background: padding-box white;
    transition: all 0.222s linear 0s;
    max-height: 450px;
    overflow: auto;
    overflow-y: scroll;
    overflow-x: hidden;
    &.active {
        opacity: 1;
        pointer-events: all;
        transform: translateY(calc(100% + 12px)) translateX(10%);
    }
}

.container-header {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #dddfe2;
    padding-bottom: 10px;
    padding: 10px;
}

.container-footer {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #dddfe2;
    padding-bottom: 10px;
    padding: 10px;
}


.container-body {
    &.emptyNotification {
        display: flex;
        justify-content: center;
        color: rgba(20, 27, 38, 0.20);
    }
}

.header-list {
    margin: 0;
    font-size: 0.85em;
    font-weight: bold;
}

.arrow {
    top: 0px;
    right: 59px;
    transform: translate(-50%, -100%);
    clip: rect(0px, 18px, 14px, -4px);
    position: absolute;
     ::after {
        content: "";
        display: block;
        width: 14px;
        height: 14px;
        transform: rotate(45deg) translate(6px, 6px);
        box-shadow: rgba(0, 0, 0, 0.44) -1px -1px 1px -1px;
        background: rgb(255, 255, 255);
    }
}

.link-button {
    font-size: 0.6em;
    vertical-align: center;
    color: #1d2637;
    cursor: pointer;
}
.link-button:hover {
    font-size: 0.6em;
    vertical-align: center;
    color: #1d2637;
    cursor: pointer;
    text-decoration: underline;
}

.link-button:active {
    font-size: 0.6em;
    vertical-align: center;
    color: rgb(0, 0, 0);
    cursor: pointer;
}
/////////////////////// LIST ///////////////////
/* .item-notification {
  cursor: pointer;
  display: flex;
  list-style: none;
  padding-left: 10px;
  padding-right: 10px;
  height: 60px;
  width: 350px;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  :hover {
    background: rgba(29, 33, 41, .04)
  }
  &.unread {
    background: #edf2fa
  }
} */
.item-notification {
  cursor: pointer;
  display: flex;
  list-style: none;
  padding-left: 10px;
  color: #489bd5;
  padding-right: 10px;
  height: 60px;
  width: 350px;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
    &.unread {
        background: #edf2fa;
    }
}
.item-notification:hover {
    cursor: pointer;
    display: flex;
    list-style: none;
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgba(29, 33, 41, .04);
    height: 60px;
    width: 350px;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
      &.unread {
          background:#e6eefc;
      }
  }
.container-name {
    font-size: 1rem;
    margin: 0;
}

.container-name-read {
    font-size: 1rem;
    color: rgba(29, 33, 41, 0.60);
    margin: 0;
}

.item-label-remark {
    font-size: 0.85rem;
    color: rgba(29, 33, 41, 0.60);
    padding-top: 1px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.item-label-date {
    font-size: 0.9rem;
    color: rgba(29, 33, 41, 0.60);
    padding-top: 1px;
    text-align: right;
    margin-right: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.container-message {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    overflow: hidden;
}

.container-time-ago {
    font-size: 0.5em;
    color: rgba(29, 33, 41, 0.60);
    width: 80px;
    text-align: right;
}

/////////////////////// END LIST ///////////////////