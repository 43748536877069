.scroll-small::-webkit-scrollbar-thumb {
  background: #eee;
}
/* Handle on hover */
.scroll-small::-webkit-scrollbar-thumb:hover {
  background: #eee;
}

.scroll-small::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scroll-small::-webkit-scrollbar-track {
  background: transparent;
}

.scroll-small::-webkit-scrollbar-thumb {
  background: rgba(177, 176, 176, 0.801);
  background-clip: content-box;
  border: 8px solid rgba(177, 176, 176, 0.801);
  height: 8px solid rgba(177, 176, 176, 0.801);
  border-radius: 100px;
}
