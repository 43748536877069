.pageA4 {
    width: 700px;
    min-height: 842px;
    margin: 10mm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.work-order-header {
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
}
.group-row {
    padding: 0;
    display: flex;
}
.title-text {
    text-align: left;
    border-top: none;
    padding: 2px 6px;
}
.detail-text {
    border-top: none;
    padding: 2px 6px;
}

.work-order-frame {
    width: 100%;
}
/* .table-process,
th,
td {
    border:  1px solid #c8ced3 ;
}
 */
.label-job {
    font-size: 5px;
    transform: rotate(-90deg);
    position: absolute;
    left: 30px;
    top: 20px;
    width: 50px;
}
.images {
    margin: auto;
    width: 78px;
    height: 100px;
  }
  .image-more {
    width: 100px;
    height: 100px;
    margin-left: -100px;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    color: #fff;
    font-size: x-large;
  }
  
