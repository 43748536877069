body {
  @import url("https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;1,100;1,200&display=swap");
  // font-family: "Kanit-pdf", sans-serif !important;
  font-family: "Sarabun", sans-serif !important;
  font-size: 1.35em !important;
  color: rgba(0, 0, 0, 0.85) !important;
}



.ant-table,
.ant-picker,
.ant-picker-cell-in-view,
.ant-select-item {
  color: rgba(0, 0, 0, 0.85) !important;
}

.main {
  background-color: #fff;
}

.text-muted,
.help-block {
  display: none;
}

input[type="text"]::-webkit-input-placeholder {
  color: rgba(90, 89, 89, 0.452);
}

.app-header {
  height: 95px;
}

.app-body {
  min-height: calc(100vh - 145px);
  margin-top: 95px !important;
}

.app-footer {
  margin-left: unset !important;
}

.sidebar {
  width: 250px !important;
  background-color: #f5f5f5 !important;
  padding-bottom: 60px;
}

.ant-tabs {
  font-size: 0.9em !important;
}

.ant-tabs-tab {
  font-size: 1em !important;
}

.form-control {
  font-size: 0.9em !important;
}

.ant-select {
  font-size: 0.9em !important;
}

.ant-select-item-option-content {
  font-size: 1.25em !important;
}

.ant-picker-input>input {
  font-size: 0.95em !important;
}

html:not([dir="rtl"]) .sidebar {
  margin-left: -250px;
}

.navbar-brand {
  width: 250px !important;
}

.sidebar .nav-item {
  width: 250px !important;
}

.sidebar .nav-title {
  background: #8a6d3b !important;
}

.sidebar .sidebar-nav {
  width: 250px !important;
}

.sidebar .nav-link {
  color: #818080;
  position: relative;
}

.sidebar .nav-link.active {
  color: #8a6d3b !important;
  background-color: #e6e6e6 !important;
  border-left: solid 8px #8a6d3b !important;
  padding-left: 8px;
}

.sidebar .nav-link.active .nav-icon {
  color: #8a6d3b;
}

.sidebar .nav-dropdown.open {
  background: transparent !important;
}

.sidebar .nav-dropdown.open .nav-link {
  color: #818080;
}

.sidebar .nav-dropdown-toggle {
  // background-color: #8a6d3b !important;
  padding: 0.75rem 1rem;
  font-size: 80%;
  font-weight: 700;
  color: #818080 !important;
  text-transform: uppercase;
}

.sidebar-fixed .app-header+.app-body .sidebar {
  height: calc(100vh - 95px);
}

.card-header {
  background-color: #2a526dcc !important;
  color: #fff !important;
  font-size: 1.25em;
}

.card-header-design-new {
  padding: 12px;
  margin-left: -12px;
  margin-right: -12px;
  background: #b9b202;
  border-radius: 8px;
  margin-bottom: 12px;
}

.card-header-design-working {
  padding: 12px;
  margin-left: -12px;
  margin-right: -12px;
  background: #07c59c;
  border-radius: 8px;
  margin-bottom: 12px;
}

.card-header-design-confirm {
  padding: 12px;
  margin-left: -12px;
  margin-right: -12px;
  background: #d84f05;
  border-radius: 8px;
  margin-bottom: 12px;
}

.card-header-design-completed {
  padding: 12px;
  margin-left: -12px;
  margin-right: -12px;
  background: #03d306;
  border-radius: 8px;
  margin-bottom: 12px;
}

.card-design {
  max-height: 750px;
  overflow-y: scroll;
  overflow-x: scroll;
}

.scroll-card {
  overflow-y: scroll;
  overflow-x: scroll;
}

.card-shadow {
  box-shadow: 0px 0px 5px 2px rgba(219, 255, 172, 0.3);
}

.detail .card-body {
  font-size: 0.8em;
}

.card-spec {
  border: 1px solid #ccc !important;
  border-radius: 16px;
  padding: 16px;
}

.card-modal {
  min-width: 750px;
  overflow-x: scroll;
}

.boottom-header {
  margin-left: 6px;
  margin-right: 6px;
  font-size: 14px;
  padding-bottom: 6px;
  padding-top: 6px;
}

a:hover {
  text-decoration: none;
}

//img product
.card-product {
  background-color: #aaa57f;
  margin: 25px;
  border-radius: 15px;
  text-align: center;
}

.card-product:hover {
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.555);
  background-color: #e7c48a;
  border-color: #e7c48a;
}

.card-img-top-product {
  width: 135px;
  height: 135px;
  padding-top: 25px;
  margin: auto;
}

.card-img-sticker-roll-type {
  width: 135px;
  height: 135px;
  margin: auto;
}

.roll-type-active {
  border: 2px solid #ff8888;
  border-radius: 8px;
  padding: 8px;
}


.card-title-product {
  text-align: center;
  color: #fff;
  font-size: 24px;
}

.text-center {
  text-align: center;
}

.No {
  width: 25px !important;
}

.card-footer {
  text-align: right;
}

.btn {
  margin-left: 4px;
  margin-right: 4px;
  font-size: 1.2rem;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn-process {
  min-width: 120px;
  padding: 8px !important;
  background-color: #8a6d3b !important;
  border-radius: 8px;
  font-size: 1.1rem;
}

.btn-add {
  align-self: center;
  margin-bottom: 8px;
}

.btn-row-sm {
  padding: 0.15em 0.4em;
  font-size: 0.9rem;
  border-radius: 0.2rem;
  margin-left: 2px;
  margin-right: 2px;
}

.btn-primary:not(:disabled):not(.disabled).active {
  /*   background-color: transparent !important; */
  border: 4px solid rgba(67, 193, 252, 0.603);
}

btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(140, 228, 240, 0.555);
}

.background-login {
  background-color: #ecc889;
}

.formgroup {
  border-top: 1px solid #ccc;
  padding-top: 18px !important;
  margin-top: 10px;
}

.formgroup-sub {
  padding: 12px;
  border-radius: 6px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.555);
  background-color: #8c8c8c21;
}

.close {
  display: none;
}

.fa-close {
  font-size: 28px;
}

.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 900px;
  }
}

@media (min-width: 992px) {

  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main,
  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer,
  html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main,
  html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 250px;
  }
}

@media (max-width: 576px) {
  .sidebar .sidebar-nav {
    overflow-x: scroll !important;
    overflow-y: scroll !important;
  }
}

@media (min-width: 576px) {

  html:not([dir="rtl"]) .sidebar-sm-show.sidebar-fixed .main,
  html:not([dir="rtl"]) .sidebar-sm-show.sidebar-fixed .app-footer,
  html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main,
  html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 250px;
  }
}

.imgPreview {
  text-align: center;
  margin: 5px 15px;
  height: 200px;
  width: 500px;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  border-top: 5px solid gray;
  border-bottom: 5px solid gray;
}

.app-header .navbar-nav {
  padding-right: 35px !important;
}

.app-header .nav-item {
  padding-right: 55px !important;
}

.app-header .nav-item .nav-link .badge {
  margin-top: -22px;
  margin-left: -26px;
}

.app-header .dropdown-menu {
  line-height: 1;
}

.btn-tab {
  padding: 6px 12px 6px 12px;
  margin: 6px 6px 12px 6px;
}

.btn-job-procecss-plus {
  border-color: transparent;
  background-color: transparent;
  color: #00b53f !important;
  font-size: 16px !important;
}

.btn-job-procecss-close {
  border-color: transparent;
  background-color: transparent;
  color: #f70000 !important;
  font-size: 16px !important;
}

.alert-infod {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.th-modal {
  text-align: center;
  background-color: #ffffff;
  padding: 3px;
}

.tabs .tab {
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.6);
  display: inline-block;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
}

.tabs .tab button {
  background-color: #20a8d8;
  color: #fff;
}

.tabs .tab :hover {
  background-color: #0ab8f7;
}

.tabs .tab .active {
  background: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
}

.content {
  padding: 20px;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(255, 255, 255, 0.2);
}

.tableJob {
  background-color: #81808030;
}

.tableJob td {
  padding: 6px 16px 6px 16px;
}

.tableJob .none-active {
  background-color: #6b3910b5;
}

.tableJob .active {
  background-color: #00d019;
}

.machine {
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
}

.delivery {
  margin-top: 5px;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  display: inline-block;
  display: flex;
}

.process {
  background-color: #8a6d3b !important;
  color: #fff !important;
  font-size: 14px;
  padding: 0.5rem 0.5rem;
  margin-bottom: 0px;
}

.process-body {
  flex: 1 1 auto;
  padding: 0.5rem;
  background-color: #f0f3f5;
  border-top: 1px solid #c8ced3;
}

.process-footer {
  padding: 0.5rem 0.5rem;
  background-color: #f0f3f5;
  border-top: 1px solid #c8ced3;
}

.modal-xl {
  max-width: 100% !important;
  height: 100% !important;
  // background-color: #00b53f0f !important;
}

.modal-dialog .modal-xl {
  max-width: 100% !important;
  height: 100% !important;
}

.showloading .modal-content {
  height: 100% !important;
  background-color: #ffffff00 !important;
}

.showloading .bg-blue {
  background-color: #ffffff00 !important;
}

.rbt-menu .dropdown-item {
  font-size: 1.1rem;
}

.draft {
  background-color: #cfcfcc !important;
  color: #fff !important;
}

.wait {
  background-color: #ffc107 !important;
  color: #fff !important;
}

.working {
  background-color: #5388ff !important;
  color: #fff !important;
}

.approve {
  background-color: #bdd549 !important;
  color: #fff !important;
}

.succeed {
  background-color: #39c96c !important;
  color: #fff !important;
}

.waiting-approve {
  background-color: #4ead86 !important;
  color: #fff !important;
}

.not-approve {
  background-color: #f86c6b !important;
  color: #fff !important;
}

.cancel {
  background-color: #888787 !important;
  color: #fff !important;
}

.label-show-date {
  color: rgb(255, 193, 7);
  padding: 6px;
  margin-right: 12px;
}

// html body .app.flex-row.align-items-center {
//   height: 75vh !important;
//   background-image: url(https://picserio.com/data/out/388/dark-background-images_5936449.png);
//   // color: rgb(255, 255, 255);
// }
.app,
app-dashboard,
app-root {
  min-height: 75vh;
}

.margin-botq {
  margin-bottom: 0px !important;
}

.table-borderless {
  margin-bottom: 0rem;
}

.custom-file-label::after {
  content: "Browse" !important;
}

.title-h3 {
  font-weight: bolder;
  font-size: 1.53125rem;
}

.title-h5 {
  font-weight: bolder;
  font-size: 1.1rem;
}

.table th,
.table td {
  padding: 8px;
}

.nav>li>a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.nav-tabs>li>a {
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
  color: #fff;
  background-color: #6c6b6b;
}

//------- custom select -------//
.css-bg1rzq-control {
  height: 36px !important;
  min-height: 36px !important;
  border: 1px solid #e4e7ea !important;
}

.css-1szy77t-control {
  height: 36px !important;
  min-height: 36px !important;
}

.css-14ojxsb-control {
  height: 36px !important;
  min-height: 36px !important;
}

.css-kj6f9i-menu {
  margin: unset !important;
}

.css-1pcexqc-container {
  width: 100%;
}

//------- custom select -------//

//------- tree -------//
.tree {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #fbfbfb;
  border: 1px solid #999;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.tree li {
  list-style-type: none;
  margin: 0;
  padding: 10px 5px 0 5px;
  position: relative;
}

.tree li::before,
.tree li::after {
  content: "";
  left: -20px;
  position: absolute;
  right: auto;
}

.tree li::before {
  border-left: 1px solid #999;
  bottom: 50px;
  height: 100%;
  top: 0;
  width: 1px;
}

.tree li::after {
  border-top: 1px solid #999;
  height: 20px;
  top: 25px;
  width: 25px;
}

.tree li span {
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border: 1px solid #999;
  border-radius: 5px;
  display: inline-block;
  padding: 3px 8px;
  text-decoration: none;
}

.tree li.parent_li>span {
  cursor: pointer;
}

.tree>ul>li::before,
.tree>ul>li::after {
  border: 0;
}

.tree li:last-child::before {
  height: 25px;
}

.tree li.parent_li>span:hover,
.tree li.parent_li>span:hover+ul li span {
  background: #eee;
  border: 1px solid #94a0b4;
  color: #000;
}

.gallery-image {
  width: 320px;
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to left, #ffffff, #e3e7e8);
}

.modal.show .modal-dialog {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.modal-open .modal {
  padding: 0px !important;
}

.image-upload {
  width: 100%;
  margin-bottom: 1rem;
}

.image-list {
  max-width: 95px;
  margin-bottom: 1rem;
}

.sidebar .nav-link .badge {
  font-size: 100% !important;
  position: absolute;
  right: 0;
  margin: auto;
  margin-right: 8px;
  width: 20px;
}

.hidden {
  display: none;
}

.list-is-invalid {
  // background-color: #ff000061 !important;
  color: #f57979 !important;
  border: 2px solid !important;
  border-radius: 6px;
}

.isdisabled {
  color: #fff;
  background-color: #c2c2c296;
  border-color: #c2c2c296;
}

.isdisabled:hover {
  color: #fff;
  background-color: #c2c2c296;
  border-color: #c2c2c296;
}

.isdisabled:click {
  color: #fff;
  background-color: #c2c2c296;
  border-color: #c2c2c296;
}

._loading {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  position: fixed;
  top: calc(50vh - 100px);
  left: calc(50vw - 100px);
}

._fullwidth {
  width: 100%;
}

.badge-circle {
  position: absolute;
  text-align: center;
  cursor: pointer;
  top: -4px;
  right: 12px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 1.75;
}

.swal2-title {
  font-size: 1.25em !important;
}

.sidebar .nav-link .badge {
  width: unset !important;
}

.btn-danger-light {
  color: #fff;
  background-color: darkorange;
  border-color: darkorange;
}

// .nav-active {
//   border: 1px solid #c8ced3 !important;
//   border-bottom: 1px solid #fff !important;
// }

.nav-active {
  border-top: 2px solid #8a6d3b !important;
  border-right: 2px solid #8a6d3b !important;
  border-left: 2px solid #8a6d3b !important;
  background-color: #e8f3f7 !important;
}

.nav-link {
  display: flex;
  align-items: center;
  // height: 65px;
}

.badge-secondary {
  color: #ffffff;
  background-color: #f9010182;
}

.badge-pill {
  margin-left: 6px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-radius: 10rem;
}

.nav-tabs .nav-link.active {
  color: #4e4242 !important;
  font-weight: 600;
  border: 0;
}

// .nav-tabs .nav-link.active {
// border-color: #f0f0f0;
//   border-top: 2px solid #f7f6f5 !important;
//   border-right: 2px solid #f7f6f5 !important;
//   border-left: 2px solid #f7f6f5 !important;
//   align-self: center;
// }
.label-machine-size {
  font-size: 16px;
  color: #ff9800;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
}

.custom-control-label:before {
  margin-top: 8.2px;
}

.custom-control-label::after {
  top: 0.68rem !important;
}

/* .swal2-styled.swal2-confirm {
  position: relative;
  padding-bottom: 6px;
  padding-top: 2px;
}
.swal2-styled.swal2-confirm:after {
  content: "ตกลง";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #4dbd74;
  border-radius: 0.25em;
}
.swal2-styled.swal2-deny {
  position: relative;
  padding-bottom: 6px;
  padding-top: 2px;
}
.swal2-styled.swal2-deny:after {
  content: "ไม่ตกลง";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #d14529;
  border-radius: 0.25em;
}
.swal2-styled.swal2-cancel {
  position: relative;
  padding-bottom: 6px;
  padding-top: 2px;
}
.swal2-styled.swal2-cancel:after {
  content: "ยกเลิก";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #757575;
  border-radius: 0.25em;
} */

.ant-picker {
  width: 100%;
}

// .e-schedule .e-appointment {
//   height: 58px !important;
// }
// .e-schedule .e-timeline-view .e-appointment, .e-schedule .e-timeline-month-view .e-appointment{
//   height: 56px !important;
// }
// .e-inner-wrap {
//   align-self: center;
// }
[class*="icono-arrow2"][class*="-left"] {
  transform: rotate(45deg);
}

[class*="icono"] {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #666;
  box-sizing: border-box;
}

[class*="icono-arrow2"] {
  width: 0;
  height: 0;
  border-width: 15px;
  border-style: solid;
  border-bottom-color: transparent;
  border-left-color: transparent;
  margin: 10px;
}

[class*="icono"] {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #666;
  box-sizing: border-box;
}

// .arrows {
//   text-align: center;
// }

// .arrows span {
//   display: block;
//   font-size: 48px;
//   line-height: 32px;
//   color: green;
//   font-weight: bold;
// }
.arrow {
  display: block;
  height: 2px;
  margin: 3px 0;
  width: 120px;
  position: relative;
  font-weight: bold;
}

.arrow.Arrow {
  background-color: red;
}

// .arrowWrapper {
//   margin-right: -20px;
//   margin-left: -20px;
// }
.arrow.Arrow:after {
  position: absolute;
  content: "";
  height: 2px;
  width: 12px;
  background: red;
  right: -1px;
  transform: rotate(45deg);
  top: -4px;
  font-weight: bold;
}

.arrow.Arrow:before {
  position: absolute;
  content: "";
  height: 2px;
  width: 12px;
  background: red;
  right: -1px;
  transform: rotate(135deg);
  top: 4px;
  font-weight: bold;
}

[class*="icono"] {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: rgb(1, 172, 238);
  box-sizing: border-box;

  &:after,
  &:before {
    content: "";
    box-sizing: border-box;
  }
}

[class*="icono-arrow2"] {
  width: 0;
  height: 0;
  border-width: 8px;
  border-style: solid;
  border-bottom-color: transparent;
  border-left-color: transparent;
  margin: 10px;

  &:before {
    right: 0;
    top: -3px;
    position: absolute;
    height: 4px;
    box-shadow: inset 0 0 0 32px;
    transform: rotate(-45deg);
    width: 200px;
    transform-origin: right top;
  }

  &[class*="-left"] {
    transform: rotate(45deg);

    &[class*="-up"] {
      transform: none;
    }

    &[class*="-down"] {
      transform: rotate(90deg);
    }
  }

  &[class*="-right"] {
    transform: rotate(-135deg);

    &[class*="-up"] {
      transform: rotate(-90deg);
    }

    &[class*="-down"] {
      transform: rotate(180deg);
    }
  }

  &[class*="-up"] {
    transform: rotate(-45deg);
  }

  &[class*="-down"] {
    transform: rotate(135deg);
  }
}

.icono-arrow2-up:before {
  width: 14px;
}

.icono-arrow2-down:before {
  width: 14px;
}

.lock-th {
  max-height: 60vh;
  overflow: hidden;
  overflow-y: scroll;

  thead {
    position: sticky;
    top: 0
  }

  tfoot {
    position: sticky;
    bottom: 0
  }
}

.lock-th-h60 {
  max-height: 60vh;
  overflow: hidden;
  overflow-y: scroll;

  thead {
    position: sticky;
    top: 0
  }

  tfoot {
    position: sticky;
    bottom: 0
  }
}